<template>
  <v-card
    color="white"
    class="rounded px-4"
    :class="[smAndDown ? 'py-8' : 'py-16']"
  >
    <div class="co-flex-col co-justify-center co-items-center">
      <span class="text-h5 my-4">{{ title }}</span>
      <span class="co-text-sm co-text-gray-500 my-4">{{ hint }}</span>

      <div
        class="co-flex-col"
        :class="[smAndDown ? 'my-4' : 'my-16']"
      >
        <span class="co-text-sm mb-2">
          <span class="primary--text">*</span>
          身份确认
        </span>
        <v-combobox
          v-model="identity"
          :items="idItems"
          outlined
          prepend-inner-icon="mdi-card-account-details-outline"
          placeholder="请选择身份"
          style="width: 320px;"
        />
      </div>
      <div
        class="co-w-full"
        :class="[smAndDown ? 'co-h8' : 'co-h24']"
      />
      <v-btn
        :loading="btnSubmit.loading"
        color="primary"
        width="240"
        height="48"
        @click="onSubmit"
      >
        {{ btnSubmit.text }}
      </v-btn>
    </div>
    <dialog-alert ref="dlgAlert" />
  </v-card>
</template>

<script>
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  export default {
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        title: '个人身份选择',
        hint: '您当前的账号仅为浏览模式，为体验更多站内功能，请完成身份认证。',
        // hint: '您当前的账号仅为浏览模式，为体验更多站内功能，请先完成身份认证，绑定完成后，您可以通过手机号登录并编辑站内内容。'
        idItems: [
          '医务工作者',
          '医学生',
          '其他'
        ],
        identity: '',
        btnSubmit: {
          loading: false,
          text: '确定'
        },
        userInfo: {}
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.identity = this.userInfo.identity || ''
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      updateInfo (data) {
        for (const key in data) {
          this.userInfo[key] = data[key]
        }
        user.info.save(this.userInfo)
        app.emit(app.event.USER_INFO_CHANGE, data)

        this.$emit('confirm', this.userInfo)
      },
      checkWidgets () {
        let result = ''
        if (!this.identity) {
          result = '请选择身份'
        }

        return result
      },
      toConfirm () {
        const me = this
        const data = {
          identity: this.identity
        }
        const executing = function () {
          me.btnSubmit.loading = true
        }

        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.updateInfo(data)
            me.$notify({
              title: '成功提示',
              message: '身份设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '身份设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
      onSubmit () {
        const result = this.checkWidgets()
        if (result) {
          // this.$notify({
          //   title: '身份没有选择提示',
          //   message: result,
          //   type: 'error'
          // })

          this.showAlert('error', '请选择身份')
          return
        }

        if (this.identity === '其他') {
          this.showAlert('error', '当前身份无法参加活动')
          return
        }

        this.toConfirm()
      }
    }
  }
</script>
